<template>
    <div class="edit-div textarea"
      v-html="innerText"
      :contenteditable="canEdit"
      :style="{'minHeight': 12 + rows * 20 + 'px' }"
      @focus="isLocked = true;"
      @blur="changeText">
    </div>
</template>
<script type="text/ecmascript-6">
export default{
  name: 'editDiv',
  props: {
    value: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      innerText: this.value,
      isLocked: false
    }
  },
  watch: {
    'value' () {
      if (!this.isLocked || !this.innerText) {
        this.innerText = this.value
      }
    }
  },
  methods: {
    changeText () {
      this.isLocked = false
      this.$emit('input', this.$el.innerText)
    }
  }
}
</script>
<style lang="less" scoped>
.edit-div {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  word-break: break-all;
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  text-align: left;
}
.edit-div[contenteditable='true']{
  user-modify: read-write-plaintext-only;
}
.edit-div[contenteditable='true']:empty:before{
  content: attr(placeholder);
  display: block;
  color: #ccc;
}
</style>
